<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)
const largerThanSm = breakpoints.greater('sm')

import type { MenuItem } from 'primevue/menuitem';
import { useRouter } from 'vue-router'
import { UserRole } from '~/lib/models/user.model';

const props = defineProps({
    firstName: String,
    role: Number,
    menuItems: Array<MenuItem>
})

const router = useRouter()
const menuVisible = ref(false);
router.beforeEach(() => {
    if (menuVisible.value) {
        menuVisible.value = false
    }
});

const { breadcrumbItems } = useBreadcrumbItems();

const handleLogout = async () => {
    try {
        await $fetch("/api/logout", {
            method: "POST",
        })
        navigateTo("/login")
    } catch (error) {
        console.error(error)
    }
}
const menu = ref();
const profileMenuItems = ref([
    {
        label: 'Profile',
        icon: 'pi pi-user text-green-500',
        command: () => {
            switch (props.role) {
                case UserRole.LOCATION_OWNER:
                    navigateTo("/locationowner/profile/");
                    break;
                case UserRole.OPERATOR:
                    navigateTo("/operator/profile");
                    break;
                case UserRole.AD_AGENCY:
                    navigateTo("/adagency/profile");
                    break;
                case UserRole.INSTALLER:
                    navigateTo("/installer/profile");
                    break;
                case UserRole.SUPER_ADMIN:
                    navigateTo("/admin/profile");
                    break;
                default:
                    navigateTo("/login");
            }
        }
    },
    {
        label: 'Change Password',
        icon: 'pi pi-lock text-green-500',
        command: () => {
            navigateTo('/changepassword');
        },
    },
    { separator: true },
    {
        label: 'Log out',
        icon: 'pi pi-sign-out text-green-500',
        command: handleLogout
    }
]);

const toggle = (event: any) => {
    menu.value.toggle(event);
};
</script>
<template>
    <div class="flex flex-col h-screen">
        <div class="w-full bg-gray-700">
            <Toolbar class="w-full bg-gray-700 border-none">
                <template #start>
                    <Button icon="pi pi-bars" severity="success" class="font-semibold text-2xl mr-2 no-hover" text
                        rounded aria-label="Menu" @click="menuVisible = true" v-if="props.role !== 3" />
                    <h2 class="text-xl md:text-2xl tracking-tight text-gray-100">Take 10 Media CMS</h2>
                </template>
                <template #end>
                    <div class="gap-2">
                        <span v-if="largerThanSm" class=" text-gray-100">Hi, {{ props?.firstName
                            }}!</span>
                        <Avatar icon="pi pi-user" class="text-green-500 cursor-pointer ml-2" @click="toggle" />
                        <Menu ref="menu" id="overlay_menu" :model="profileMenuItems" :popup="true"
                            class="bg-gray-500" />
                    </div>
                </template>
            </Toolbar>
            <div class="w-full bg-gray-100 border-none">
                <Breadcrumbs :breadcrumbs="breadcrumbItems" v-if="role !== 3" />
            </div>
        </div>
        <div class="flex-grow px-0 md:px-4 pb-4 overflow-y-auto">
            <slot />
        </div>
        <footer class="py-4 px-2 mt-auto">
            <p>Copyright &copy; 2024 Take10 Media. All rights reserved.</p>
        </footer>
        <Drawer v-model:visible="menuVisible" class="bg-gray-500 border-none">
            <Menu :model="props?.menuItems" class="bg-gray-500 text-lg">
                <template #item="{ item, props }">
                    <NuxtLink v-if="item.route" v-ripple :to="item.route">
                        <template v-slot="{ isExactActive }">
                            <div class="my-1" :class="[isExactActive ? 'router-link-exact-active' : 'text-white']">
                                <i class="mx-2" :class="[item.icon]" />
                                <span>{{ item.label }}</span>
                            </div>
                        </template>
                    </NuxtLink>
                    <NuxtLink v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
                        <template v-slot="{ isExactActive }">
                            <div :class="[isExactActive ? 'router-link-exact-active' : 'text-white']">
                                <i class="mx-2" :class="item.icon" />
                                <span>{{ item.label }}</span>
                            </div>
                        </template>
                    </NuxtLink>
                </template>
            </Menu>
        </Drawer>
    </div>

</template>

<style>
.no-hover:hover {
    background-color: transparent;
}

.p-drawer-header {
    justify-content: flex-end;
    color: white;

    .p-button-text.p-button-secondary:not(:disabled):active {
        color: white;
        outline: none;
        background: transparent;
    }
}

.p-drawer-close-button {
    color: white !important;
}
</style>