<script setup lang="ts">
import type { MenuItem } from 'primevue/menuitem';

defineProps({
    breadcrumbs: Array<MenuItem>
})

import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
const breakpoints = useBreakpoints(breakpointsTailwind)
const largerThanSm = breakpoints.greater('sm')

</script>

<template>
    <Breadcrumb :model="breadcrumbs" class="bg-transparent" v-if="breadcrumbs && breadcrumbs?.length > 0">
        <template #item="{ item }">
            <span v-if="item.icon">
                <NuxtLink :to=item.url ><i :class="item.icon"></i></NuxtLink>
            </span>
            <span v-else>
                <NuxtLink v-if="item.url" :to="item.url" :class="['cursor-pointer', 'no-underline', 'hover:underline', largerThanSm ? 'text-xl' : 'text-sm']">{{
                    item.label }}
                </NuxtLink>
                <span v-else :class="['text-sm', largerThanSm ? 'text-xl' : 'text-sm', { 'font-semibold': item.highlight }]">
                    {{ item.label }}
                </span>
            </span>
        </template>
    </Breadcrumb>
</template>

<style scoped>
.pi-home {
    cursor: pointer;
    color: var(--p-green-500);
    font-size: 1.5rem;
}
</style>
